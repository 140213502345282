/* eslint-disable @typescript-eslint/no-var-requires */
import { abstractView } from "./AbstractView";
import {
  checkForUTMParameters,
  getUserData,
  getUserZipCode,
  saveReferralData,
  saveUserAddress,
} from "../helpers/UserData";
import { handleAddressLookup, AddressSuggestion } from "../helpers/Smarty";
import { currentReferralProgramAmount } from "../schema/operations";
import { handleInput } from "../helpers/Utils";
import { processFormSubmission } from "../helpers/FormSubmission";

export function landingView() {
  checkForUTMParameters();
  const mobileHeroImageURL = require("../static/img/handyman-tablet@4x.webp");
  const desktopHeroImageURL = require("../static/img/handyman-large.webp");
  const waveURL = require("../static/img/curve-reverse.svg");
  let viewName = "LandingView";
  let subheader =
    "<span class='line'>Get started with a no-obligation home walk-thru to meet your dedicated handyman.";

  // Only show the address auto-complete page if it's turned on
  // and the user didn't click
  const qs = new URLSearchParams(window.location.search);
  if (qs.get("checkUsingZipCode") !== "true") {
    viewName = "LandingViewAddress";
  }
  const view = abstractView(viewName, {
    mobileHeroImageURL: mobileHeroImageURL,
    desktopHeroImageURL: desktopHeroImageURL,
    waveURL: waveURL,
  });
  if (referralInfo() === undefined) {
    view.setTitle("Honey Homes - Check Your Address");
  } else {
    view.setTitle("Honey Homes - Referral Address Check");
  }
  view.onload = (): void => {
    prepopulateUserData();
    if (referralInfo()) {
      currentReferralProgramAmount()
        .then((result) => {
          if (result.ok) {
            subheader = `Sign up for a free trial visit and get $${result.val / 100} off when you join.`;
          } else {
            console.log("Error: ", result.val);
            throw result.val;
          }
          populateSubHeaderText(subheader);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } else {
      populateSubHeaderText(subheader);
    }
    attachEventHandlers();
  };
  return view;
}

function prepopulateUserData() {
  const zipCodeOption = getUserZipCode();
  if (zipCodeOption.some) {
    const zipCodeField = document.getElementById("zipCode") as HTMLInputElement;
    if (zipCodeField) {
      zipCodeField.value = zipCodeOption.val;
    }
    handleInput.bind(zipCodeField)();
  }
}

function referralInfo(): [referredBy: string, code: string] | undefined {
  const params = new URLSearchParams(window.location.search);
  if (params.has("referredBy") && params.has("referredById")) {
    const referredBy = params.get("referredBy");
    const referredById = params.get("referredById");
    if (referredBy && referredById) {
      saveReferralData({
        referredById: referredById,
        referredBy: referredBy,
      });
      return [referredBy, referredById];
    }
  } else {
    const data = getUserData();
    if (data?.referredBy && data?.referredById) {
      return [data.referredBy, data.referredById];
    }
  }
  return;
}

function populateSubHeaderText(subheader: string): void {
  const subheaderElement = document.querySelector(".subheader");
  if (subheaderElement) {
    subheaderElement.innerHTML = subheader;
  }
}

async function handleAddressAutocomplete(address: HTMLInputElement) {
  const addressResults = await handleAddressLookup(address.value);
  if (addressResults.ok) {
    renderSuggestions(addressResults.val || []);
  } else {
    console.error(
      `Error looking up address with Smarty: ${addressResults.val}`,
    );
  }
}

function updateZipCodeInput(zipCodeValue: string) {
  const zipCode = document.getElementById("zipCode") as HTMLInputElement;
  if (zipCode) {
    zipCode.value = zipCodeValue;
  }
}

function attachEventHandlers(): void {
  // Handle Input Util
  const arrInputFields = document.querySelectorAll(
    "input",
  ) as NodeListOf<HTMLInputElement>;
  arrInputFields.forEach((inputField) => {
    inputField.addEventListener("keyup", handleInput);
  });

  // Address Autocomplete
  const addressInput = document.getElementById(
    "streetAddress",
  ) as HTMLInputElement;
  if (addressInput) {
    addressInput.addEventListener("input", async (e: Event) => {
      // Only trigger the lookup if the user has typed more than 0 characters
      if (e && (e.target as HTMLInputElement).value.length > 0) {
        await handleAddressAutocomplete(addressInput);
      } else {
        clearSuggestions();
      }
    });
    let activeSuggestionIndex = -1; // Index of the currently highlighted suggestion

    addressInput.addEventListener("keydown", async (e: KeyboardEvent) => {
      const suggestionsList = document.getElementById("suggestions");
      const suggestions = suggestionsList?.getElementsByTagName("li");
      if (!suggestions || suggestions.length === 0) return;

      function highlightSuggestion() {
        if (suggestionsList) {
          // Remove highlighting from all suggestions
          for (let i = 0; i < suggestionsList.children.length; i++) {
            suggestionsList.children[i].classList.remove("highlighted");
          }
          // Highlight the new active suggestion
          suggestionsList.children[activeSuggestionIndex].classList.add(
            "highlighted",
          );
          // Update input field with the current highlighted suggestion's text
          addressInput.value =
            suggestionsList.children[activeSuggestionIndex].textContent ?? "";
        }
      }

      // Down arrow key
      if (e.key === "ArrowDown") {
        activeSuggestionIndex =
          (activeSuggestionIndex + 1) % suggestions.length;
        highlightSuggestion();
        e.preventDefault(); // Prevent the cursor from moving in the input field
      }
      // Up arrow key
      else if (e.key === "ArrowUp") {
        if (activeSuggestionIndex <= 0) {
          activeSuggestionIndex = suggestions.length - 1;
        } else {
          activeSuggestionIndex -= 1;
        }
        highlightSuggestion();
        e.preventDefault(); // Prevent the cursor from moving in the input field
      }
      // Enter key
      else if (e.key === "Enter") {
        if (activeSuggestionIndex >= 0) {
          suggestions[activeSuggestionIndex].click();
          // e.preventDefault(); // Prevent form from submitting when suggestions are active
        } else {
          // If no suggestion is active, submit the form
          const addressForm = document.getElementById("address-form");
          await processFormSubmission(addressForm as HTMLFormElement);
        }
      }
    });
  }
}

function renderSuggestions(suggestions: AddressSuggestion[]) {
  const suggestionsList = document.getElementById("suggestions");
  clearSuggestions();

  if (suggestionsList) {
    suggestions.forEach((suggestion) => {
      const handleAddressClick = async () => {
        const addressInput = document.getElementById(
          "streetAddress",
        ) as HTMLInputElement;
        if (addressInput) {
          // Populate input with selected suggestion and update zip code input
          saveUserAddress(JSON.stringify(suggestion));
          addressInput.value = suggestion.streetAddress;
          updateZipCodeInput(suggestion.zipcode);
          const addressForm = document.getElementById("address-form");
          await processFormSubmission(addressForm as HTMLFormElement);
        }
        clearSuggestions();
      };
      const li = document.createElement("li");
      li.textContent = suggestion.streetAddress;
      li.addEventListener("click", handleAddressClick);
      suggestionsList.appendChild(li);
    });
  }
}

function clearSuggestions() {
  const suggestionsList = document.getElementById("suggestions");
  if (suggestionsList) {
    while (suggestionsList.firstChild) {
      suggestionsList.removeChild(suggestionsList.firstChild);
    }
  }
}
